<template>
	<input ref="input"
	       :value="modelValue"
	       class="form-control"
	       @input="$emit('update:modelValue', $event.target.value)"
	>
</template>

<script setup>
import { onMounted, ref } from 'vue';

// eslint-disable-next-line no-undef
defineProps({
	modelValue: String,
});

// eslint-disable-next-line no-undef
defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
	if (input.value.hasAttribute('autofocus')) {
		input.value.focus();
	}
});

defineExpose({ focus: () => input.value.focus() });
</script>
